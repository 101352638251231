<template>
  <div class="legend-artist" v-if="model">
    <div class="legend-artist-background">
      <img :src="model.fileName" alt="İçerik" />
    </div>
    <div class="content-bar">
      <div class="content-cont">
        <div class="title wow fadeInUp" data-wow-delay="0.6s"></div>
        <div class="detail-text wow fadeIn" data-wow-delay="2s">
          <strong class="name">{{model.title}}</strong>

          <img src="/images/bg/efsane2-line.png" alt />
          <div class="content">
            <span v-html="model.description"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import WOW from "wow.js";
new WOW().init();
export default {
  name: "legend-artist",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, this.model.displaySecond * 1000);
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundImage;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";
@font-face {
  font-family: "Montserrat-Bold";
  src: url(/fonts/Montserrat-Bold.otf);
}
.legend-artist {
  .legend-artist-background {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .content-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 100vw;
    .content-cont {
      width: 100%;
      text-align: left;
      margin: auto;
      .title {
        width: 421px;
        height: 296px;
        position: relative;
        z-index: 55;
        background: url("/images/bg/efsane1.png") center no-repeat;
        background-size: 100% 100%;
        margin-left: 250px;
      }
      .detail-text {
        background: url("/images/bg/efsane2.png") center no-repeat;
        background-size: 100% 100%;
        position: relative;
        z-index: 52;
        margin: 0 286px;
        top: -102px;
        padding: 28px 4px;
        color: white;
        font-size: 36px;
        line-height: 1.2;
        text-align: left;
        img {
          position: absolute;
          height: 95%;
          top: 5px;
          margin-left: -25px;
        }
        .name {
          width: 345px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 30px;
          text-align: center;
          font-family: "Montserrat-Bold";
          font-size: 40px;
          margin-top: 0px;
          min-height: 150px;
          padding-top: 50px;
        }
        .content {
          max-width: calc(100% - 380px);
          display: inline-block;
          vertical-align: middle;

          span {
            -webkit-line-clamp: 6;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            font-size: 30px;
          }
        }
      }
    }
  }
}
</style>
